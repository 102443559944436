html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  /* Overflow should not be set to hidden to allow content scrolling */
}

/*
.MuiSlider-mark {
}

.MuiSlider-root {
  color: black;
  font-weight: bold;
  text-decoration: underline;
}*/

.weather_icon {
  width: 20px;
  height: auto;
}

.temperature_inactive {
  font-size: small;
  color: grey;
}

.temperature_active {
  font-size: small;
  color: black;
}

.hour_inactive {
  font-size: medium;
  color: grey;
}

.hour_active {
  font-size: medium;
  color: black;
}

/* Reset zoom and max-zoom */
@viewport {
  zoom: 1;
  max-zoom: 1;
}

#map {
  width: 100%;
  height: calc(75vh);
  position: relative;
}

@media (max-width: 900px) {
  #map {
    height: calc(71vh);
  }
}

.leaftlet-map {
  width: 100%;
  height: 100%;
}

/* Styles for the app container */
.app-container {
  display: grid;
  grid-template-rows: auto 1fr;
  /* Updated to allow the header to take its content's height */
  height: 100vh;
  overflow: scroll;
}

.simulator-container #map {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* This will create two equally wide columns */
  height: 99vh;
  width: 100vw;
}

.simulator-map-container {
  width: 50vw;
}

.simulator-form-component {
  margin: 40px 40px 40px 2px;
}

/* Styles for the header container */
.header-container {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Adjust the logo size based on screen size */
.logo {
  width: 130px;
  height: auto;
}

.dropdown-item {
  z-index: 3;
  /* Makes sure dropdownis visible */
}

.slider-item {
  z-index: 4;
  /* Makes sure slider is visible */
  height: 110px;
}

.info-item {
  align-self: flex-start;
}


/* Remove zoom from the map container */
.map-container {
  zoom: 1;
  position: relative;

}

.header-container.disabled {
  height: 0;
  overflow: hidden;
}

.disabled {
  pointer-events: none;
  /* disables general controls */
}

.disabled .leaflet-control-zoom {
  display: none;
  /* disables leaflet zoom box */
}


/* Styles for map cluster icons */

.custom-cluster-icon {
  background-color: #E6F2FF;
  color: #000000;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
}

.custom-cluster-icon-score01 {
  background-color: #F2F2F2;
  color: #000000;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
}

.custom-cluster-icon-score02 {
  background-color: #E6E6E6;
  color: #000000;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  width: 40px;
  height: 40px;
}

.custom-cluster-icon-score03 {
  background-color: #D9D9D9;
  color: #000000;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
}

.custom-cluster-icon-score04 {
  background-color: #C6C6C6;
  color: #000000;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
}

.custom-cluster-icon-score05 {
  background-color: #B0B0B0;
  color: #000000;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
}

.custom-cluster-icon-score06 {
  background-color: #FFF7D4;
  color: #000000;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
}

.custom-cluster-icon-score07 {
  background-color: #FFEDAD;
  color: #000000;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
}

.custom-cluster-icon-score08 {
  background-color: #FFDF7D;
  color: #000000;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
}

.custom-cluster-icon-score09 {
  background-color: #FFD34C;
  color: #000000;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
}

.custom-cluster-icon-score10 {
  background-color: #FF9B00;
  color: #000000;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
}

.simulator-form-component {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 1000;
  background-color: #ffffff;
  padding: 10px;
  max-height: 70vh;
  overflow-y: auto;
}

.fullscreen {
  height: 100vh;
}

.map-container.fullscreen #map {
  height: 100vh;
  width: 100%;
}


.toggle-header-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
  /* Make sure the button is above other elements */
  background-color: transparent;
  border: none;
  padding: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: color 0.3s;
  color: #333;
}


.night-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  pointer-events: none;
}

@media screen and (max-width: 600px) {
  .header-container {
    min-height: 60px;
    flex-direction: column;
    align-items: center;
  }

  /* Adjust the logo size for smaller screens */
  .logo {
    width: 100px;
  }

  .header-container.disabled {
    height: 0;
    min-height: 0;
    overflow: hidden;
  }
}

.preference-selector-container {
  display: flex;
  align-items: center;
}

.icon-button-selector {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}